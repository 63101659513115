html {
  height: 100%;
}

body {
  height: 100%;
}

a:link {
  color: inherit;
}
a:visited {
  color: inherit;
}
a:hover {
  color: inherit;
}

div[id^="ViewSelector"] {
  z-index: 20;
}
#__flyoutRootNode .flexbox {
  z-index: 20;
}

.person-details-container {
  z-index: 1000;
  float: left;
}

.ms-List {
  z-index: 1;
}

.danger {
  color: red;
}

.currency {
  text-align: right;
  font-size: 14px;
  padding: 0px, 8px;
  margin: 0px;
  line-height: 1.15;
  min-height: 24px;
}

.titleField {
  font-size: "2em" !important;
  font-weight: bold !important;
  border: none;
  background: inherit !important;
}

.rowSelectColumn {
  flex-grow: 0.15 !important;
}

.mgt-light {
  --input-border: none;
  --background-color: #f3f2f1;
}

input {
  background-color: var(--background-color, white);
  color: var(--color, black);
  border: none;
}

.mgt-dark {
  --input-border: none;
  --background-color: #201f1f;
}

/* variables used for mgt-react dark mode (assign this class) */
.nv {
  --background-color: #201f1f;
  --placeholder-color: white; /* placeholder text color */
  --selected-person-background-color: #6b6b6b; /* person item background color */
  --color: white;
  --color-sub1: white;
  --my-dropdown-item-hover-background: #00405e;
}

/* variables used for mgt-react light mode (assign this class) */
.hl {
  --background-color: #f3f2f1;
  --placeholder-color: #201f1f; /* placeholder text color */
  --selected-person-background-color: #d1d1d1; /* person item background color */
  --color: black;
  --color-sub1: black;
  --my-dropdown-item-hover-background: #e5e5f1;
}

.darkRequired {
  color: yellow;
}

.lightRequired {
  color: red;
}

/* custom properties from: https://docs.microsoft.com/en-us/graph/toolkit/components/people-picker */
mgt-people-picker {
  --input-border: none;

  --input-background-color: var(
    --background-color,
    rgb(243, 242, 241)
  ); /* input area background color */

  --dropdown-background-color: var(
    --background-color,
    rgb(243, 242, 241)
  ); /* selection area background color */
  --dropdown-item-hover-background: var(
    --my-dropdown-item-hover-background,
    #e5e5f1
  ); /* person background color on hover */

  --color: white; /* input area border focus color */
  --placeholder-color--focus: rgba(
    255,
    255,
    255,
    0.8
  ); /* placeholder text focus color */
}

.carnaBreadcrumb {
  width: 100%;
  padding: 10px;
  padding-left: 100px;
  position: fixed;
  top: 0;
  z-index: 100;
}

.carnaLeftNav {
  background: local;
  /* height: 80vh;
  overflow-y:auto; */
  padding: 5px;
}

.carnaMainWindow {
  width: 80%;
  float: right;
  height: 100vh;
  padding: 20px;
  position: sticky;
  padding-top: 50px;
}

.showThis {
  display: block;
}

.hideThis {
  display: none;
}

.carnaForm {
  width: 100%;
  padding: 10px;
}

.carnaRow {
  width: 100%;
}

.carnaColSmall {
  width: 20%;
  float: left;
  position: relative;
  padding: 5px;
  padding-right: 15px;
  margin-bottom: 10px;
}

.carnaColQuart {
  width: 25%;
  float: left;
  position: relative;
  padding: 5px;
  padding-right: 15px;
  margin-bottom: 10px;
}

.carnaCol30 {
  width: 30%;
  float: left;
  position: relative;
  padding: 5px;
  padding-right: 15px;
  margin-bottom: 10px;
}

.carnaColHalf {
  width: 50%;
  float: left;
  position: relative;
  padding: 5px;
  padding-right: 15px;
  margin-bottom: 10px;
}

.carnaColLarge {
  width: 80%;
  float: left;
  position: relative;
  padding: 5px;
  padding-right: 15px;
  margin-bottom: 10px;
}

.carnaColFull {
  width: 100%;
  float: left;
  position: relative;
  padding: 5px;
  padding-right: 15px;
}

.blockText {
  white-space: pre-wrap;
}

.fieldLabel {
  font-size: smaller;
  padding-left: 10px;
}
.carnaProjectFinance {
  width: 40%;
  position: relative;
  float: left;
  margin: 20px;
}

.carnaFinancePane {
  border: black;
  border-style: solid;
  border-width: 1px;
  float: left;
  padding: 10px;
  width: 100%;
}

.carnaFinanceRow {
  display: flex;
}

.carnaFinanceLeft {
  width: 50%;
  float: left;
  padding: 5px;
  margin-bottom: 10px;
  text-align: left;
}

.carnaFinanceRight {
  width: 50%;
  float: right;
  padding: 5px;
  margin-bottom: 10px;
  text-align: right;
}

.noteTitle {
  font-size: 0.75rem;
  word-wrap: break-word;
}

.noteDate {
  font-size: 0.75rem;
  text-align: end;
}

.notesBox {
  width: 100%;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  height: 300px;
  overflow-y: scroll;
  word-wrap: break-word;
}

.attachmentUpload {
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 8px 5px 6px;
  border: 2px solid;
  border-radius: 2px;
}

.attachmentUpload:hover {
  border-color: #f0f0f0;
}

.attachmentSpacer {
  margin-top: 10px;
}

.attachmentUploadBox {
  display: none;
}

.attachmentHeader {
  padding-top: 15px;
  padding-bottom: 15px;
}

.ms-DetailsRow {
  align-items: center;
  vertical-align: center;
  display: flex;
}

.ms-DetailsRow-cell {
  align-items: center;
  vertical-align: center;
  display: flex;
}

.carnaPhotoCell {
  display: flex;
}

.carnaPhoto {
  border-radius: 50%;
  vertical-align: bottom;
  margin: 3px;
}

.greenHealth {
  display: inline-flex;
  padding: 0.25em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  box-sizing: border-box;
  color: white;
  background: #00c851;
  padding: 10px;
  height: 24px;
  border-radius: 16px;
  align-items: center;
}
.yellowHealth {
  display: inline-flex;
  padding: 0.25em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  box-sizing: border-box;
  color: white;
  background: #fb3;
  padding: 10px;
  height: 24px;
  border-radius: 16px;
  align-items: center;
}
.redHealth {
  display: inline-flex;
  padding: 0.25em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  box-sizing: border-box;
  color: white;
  background: #ff3547;
  padding: 10px;
  height: 24px;
  border-radius: 16px;
  align-items: center;
}
.notStartedHealth {
  display: inline-flex;
  padding: 0.25em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  box-sizing: border-box;
  color: white;
  background: #17A2B8;
  padding: 10px;
  height: 24px;
  border-radius: 16px;
  align-items: center;
}
.closedHealth {
  display: inline-flex;
  padding: 0.25em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  box-sizing: border-box;
  color: white;
  background: black;
  padding: 10px;
  height: 24px;
  border-radius: 16px;
  align-items: center;
}
.noHealth {
  color: #000000;
  background: #f7f7f7;
  border-radius: 20%/50%;
}

.ReactModal__Overlay {
  overflow: auto;
}

.ReactModal__Body--open {
  height: 100%;
  overflow: hidden;
}

.ui-dialog__content {
  overflow: auto;
}

.ui-dialog {
  overflow: hidden;
}

.ui-table__row {
  height: inherit !important;
}

.ui-table__cell__content {
  width: 100%;
  word-wrap: break-word;
}

.ms-Dialog-main {
  height: 100%;
  width: 80vw;
  padding: 10px;
}

.ms-Modal {
  overflow: hidden;
}

.ms-Modal-scrollableContent {
  height: 100%;
  overflow: auto;
}

.close-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.topright {
  position: absolute;
  right: 20px;
  top: 10px;
}

.errorText {
  animation-name: css-1, css-14;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 400;
  color: rgb(164, 38, 44);
  margin: 0px;
  padding-top: 5px;
  display: flex;
  align-items: center;
}

.goodText {
  animation-name: css-1, css-14;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 400;
  color: #13a40e;
  margin: 0px;
  padding-top: 5px;
  display: flex;
  align-items: center;
}

.characters-remaining {
  display: none;
}

textarea:focus + .characters-remaining {
  display: block;
}

.lightStyleNorthstar {
  -webkit-text-size-adjust: 100% !important;
  -webkit-font-smoothing: antialiased !important;
  box-sizing: border-box !important;
  font-family: inherit !important;
  font-size: 100% !important;
  line-height: 1.15 !important;
  overflow: auto !important;
  color: rgb(37, 36, 35) !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  outline-width: 0px !important;
  border-top-style: solid !important;
  border-right-style: solid !important;
  border-bottom-style: solid !important;
  border-left-style: solid !important;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  width: 100% !important;
  margin-top: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-bottom: 0px !important;
  height: auto !important;
  background-color: rgb(243, 242, 241) !important;
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  border-top-width: 0px !important;
  border-bottom-width: 0.125rem !important;
  padding-top: 0.4375rem !important;
  padding-right: 0.75rem !important;
  padding-bottom: 0.4375rem !important;
  padding-left: 0.75rem !important;
  resize: none !important;
}
