.nav-wrapper {
  margin: -5px;
  padding-right: 7px;
  text-align: right;
}
.nav-wrapper .title {
  background: linear-gradient(to left, #891d1d, #e4893b, #1d988c);
  color: transparent;
  -webkit-background-clip: text;
  font-size: 20px;
}
.nav-wrapper h2 {
  font-size: 18px;
}

.nav-wrapper .summary-wrapper {
  padding-top: 10px;
}

.nav-wrapper .budget-wrapper {
  padding-top: 60px;
}

.nav-wrapper .risk-wrapper {
  padding-top: 190px;
}

.nav-wrapper .task-wrapper {
  padding-top: 90px;
}

.nav-wrapper .task-wrapper h2 {
  margin-block-end: 12px;
}

.nav-wrapper .task-wrapper p {
  margin-block-end: 0px;
  margin-block-start: 8px;
}

.nav-wrapper .timeline-wrapper {
  padding-top: 50px;
}

.nav-wrapper .timeline-wrapper h2 {
  margin-block-end: 12px;
}

.nav-wrapper .timeline-wrapper p {
  margin-block-end: 0px;
  margin-block-start: 8px;
}

.section-black-indicator {
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 20px;
  margin-bottom: -3px;
  background-color: #111921;
}

.section-blue-indicator {
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 20px;
  margin-bottom: -3px;
  background-color: #1d4489;
}

.status-orange {
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e4893b;
  margin-right: 8px;
}

.status-red {
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #891d1d;
  margin-right: 8px;
}

.status-green {
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #29AD4C;
  margin-right: 8px;
}

.status-black {
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #111921;
  margin-right: 8px;
}

.status-blue {
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0468A5;
  margin-right: 8px;
}
