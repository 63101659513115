.card-block-wrapper {
  height: 100%;
  padding-left: 7px;
  padding-right: 7px;
  box-sizing: border-box;
  color: #fff;
}

.card-expand-wrapper {
  padding-left: 10px;
}

.card-title .card-expand-wrapper {
  float: right;
}

.card-block-wrapper h2 {
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.chart-wrapper {
  display: flex;
  justify-content: center;
}

.card-title {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 14px;
  text-align: center;
  text-decoration: none;
}

.card-title-link {
  text-decoration: none;
}

.card-title-link:hover {
  color: #1d4489;
}

.card-summary {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 62px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.container {
  overflow: hidden;
  width: 100%;
  margin: 0px;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.8);
  transition: box-shadow 0.3s;
}

.container svg {
  display: block;
}

section {
  position: relative;
  background: #1d4489;
  color: #fff;
  text-align: center;
}

.row {
  background: #1d4489;
}
.col-2 .column {
  width: 49%;
}

.col-3 .column {
  padding: 0 1%;
  width: 33%;
  text-align: center;
}

.col-3 .column p {
  padding: 1.4em;
}

.column {
  display: inline-block;
  vertical-align: top;
}

.color {
  background: #111921;
}

/* Common style for pseudo-elements */
section::before,
section::after {
  position: absolute;
  content: "";
  pointer-events: none;
}

/* Double Diagonal line */
.ss-style-doublediagonal {
  z-index: 1;
  padding-top: 6em;
  background: #111921;
}

.ss-style-doublediagonal::before,
.ss-style-doublediagonal::after {
  top: 0;
  left: -25%;
  z-index: -1;
  width: 150%;
  height: 75%;
  background: inherit;
  -webkit-transform: rotate(-2deg);
  transform: rotate(-2deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ss-style-doublediagonal::before {
  height: 50%;
  background: #132337;
  -webkit-transform: rotate(-3deg);
  transform: rotate(-3deg);
  -webkit-transform-origin: 3% 0;
  transform-origin: 3% 0;
}

/* Big Triangle */
svg#bigTriangleColor {
  pointer-events: none;
}

#bigTriangleColor path {
  fill: #111921;
  stroke: #111921;
  stroke-width: 2;
}

/* Clouds */
#clouds path {
  fill: #1d4489;
  stroke: #1d4489;
}

.clouds {
  background: #111921;
}

.clouds p {
  color: #333;
}

.clouds a {
  color: #004d4d;
  text-decoration: none;
}

.clouds a:hover {
  color: black;
}

.clouds-wrapper {
  padding: 0px;
  margin-top: -20px;
  z-index: 9;
  background-color: #111921;
}

/* Waves */
.waves-wrapper {
  z-index: 9;
  background-color: #1d4489;
}

/* Media Queries for the layout */
@media screen and (max-width: 576px) {
  .card-block-wrapper {
    padding-right: 6px;
    padding-bottom: 18px;
    padding-top: 6px;
    padding-left: 6px;
  }
  .card-title {
    padding-bottom: 20px;
    font-size: 20px;
  }
}

@media screen and (max-width: 68em) {
  section {
    font-size: 80%;
  }
}
@media screen and (max-width: 54em) {
  .column {
    display: block;
    width: 100% !important;
  }

  .text:first-child,
  .text:nth-child(2) {
    text-align: center;
  }

  .icon {
    margin: 0 auto;
  }

  .col-2 .column:first-child .icon {
    margin: 0 auto 50px;
  }

  .col-2 .column:nth-child(2) .icon {
    margin: 50px auto 0;
  }
}
