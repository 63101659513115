.project-nav-wrapper {
  text-align: center;
  position: sticky;
  top: 0px;
  z-index: 200;
  background: white;
  padding-bottom: 1em;
  padding-top: 1em;
  margin: -1em;
  margin-top: -17px;
}

.project-nav-wrapper .left-icon {
  margin-top: -2px;
  float: left;
  padding-left: 1em;
}

.project-nav-wrapper .right-icon {
  margin-top: -2px;
  float: right;
  padding-right: 1em;
}
