.nav-btn-wrapper {
  float: right;
}

.nav-bar-link {
  margin-left: 10px;
  padding: 4px 8px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid rgb(138, 136, 134);
  border-radius: 2px;
  color: rgb(50, 49, 48);
}

.nav-bar-link:hover {
  background-color: rgb(243, 242, 241);
  color: rgb(32, 31, 30);
}

.current {
  color: #5b5fc7 !important;
  border-color: #5b5fc7 !important;
}

.current-dark {
  color: #7f85f5 !important;
  border-color: #7f85f5 !important;
}

.nav-bar-wrapper {
  padding-left: 7px;
  padding-right: 7px;
  position: sticky;
  top: 0px;
}

.nav-bar-icons {
  margin-top: -5px;
}

.mobile-nav-bar-link {
  font-size: 16px;
  text-decoration: none;
  border-bottom: 1px solid rgb(201, 201, 201);
  color: rgb(50, 49, 48);
  width: 100%;
  padding-bottom: 10px;
  padding-top: 12px;
}

.mobile-current {
  color: rgb(32, 31, 30);
  border-bottom: 4px solid #5b5fc7 !important;
  font-weight: 600;
}

.mobile-dark {
  background: #141414;
  border-bottom: 1px solid rgb(66, 66, 66);
}

.mobile-current-dark {
  border-bottom: 4px solid #7f85f5 !important;
}

.mobile-nav-bar-wrapper {
  display: flex;
  text-align: center;
  margin: -1em;
  margin-top: -17px;
  position: sticky;
  top: 0px;
}
