.custom-tool-tip {
  border: 1px solid #f5f5f5;
  background-color: white;
  padding: 10px;
  color: Black;
}
.custom-tool-tip .title {
  font-weight: 600;
}

.custom-tool-tip p {
  margin-block-start: 0px;
  margin-block-end: 5px;
}
